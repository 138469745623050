<script setup>
  import { computed, onMounted } from 'vue';
  import PowertrainSection from '@/components/configuratorSections/PowertrainSection';
  import ChassisSectionV2 from '@/components/configuratorSections/ChassisSectionV2';
  import FifthWheelSection from '@/components/configuratorSections/FifthWheelSection';
  import FuelEfficiencySection from '@/components/configuratorSections/FuelEfficiencySection';
  import ConnectedServicesSection from '@/components/configuratorSections/ConnectedServicesSection';
  import SafetySectionGroup from '@/components/configuratorSections/SafetySectionGroup';
  import RepairMaintenanceSection from '@/components/configuratorSections/RepairMaintenanceSection';
  import ExteriorSection from '@/components/configuratorSections/ExteriorSection.vue';
  import InteriorSection from '@/components/configuratorSections/InteriorSection';
  import { useMainStore } from '@/store/index.js';

  const store = useMainStore();
  const staticImagesBaseUrl = process.env.VUE_APP_DS_STATIC_IMAGES_BASE_URL;

  defineProps({
    title: {
      type: String,
      default: '',
    },
    offerTemplate: {
      type: String,
      default: 'DE_STANDARDA4X2NA',
      required: true,
    },
    specType: {
      type: String,
      default: '',
      required: true,
    },
    isFrameAgreement: {
      type: Boolean,
      default: false,
      required: true,
    },
  });

  const emit = defineEmits(['select']);

  const options = computed(() => {
    return store.options;
  });

  const images = computed(() => {
    return store.images;
  });

  const interiorImages = computed(() => {
    return { interior: images.value.interior, dashboard: images.value.dashboard };
  });

  const imageChassisSection = computed(() => {
    return (store.sectionMetadata?.chassis?.descriptionImage) ? `${staticImagesBaseUrl}/${store.sectionMetadata?.chassis?.descriptionImage}` : null;
  });
  
  const chassisHighlights = computed(() => {
    return store.misc?.chassisHighlights;
  });

  onMounted(() => {
    //set custom sections
    store.sections = [
        { id: 'powertrain', name: 'drivelineHeaderTitle' },
        { id: 'exterior', name: 'exteriorHeaderTitle' },
        { id: 'interior', name: 'interiorHeaderTitle' },
        { id: 'chassis', name: 'chassisHeaderTitle' },
        { id: 'connectedServices', name: 'connectedServicesHeaderTitle' },
        { id: 'repairMaintenance', name: 'repairMaintenanceHeaderTitle' },
      ];
  });
</script>

<template>
  <PowertrainSection
    :items="options.driveline"
    @select="emit('select', $event, 'driveline')"
  />
  <ExteriorSection
    :cab-colour="options.cabColour"
    :exterior="options.exterior"
    :images="images"
    @select="emit('select', $event)"
  />
  <InteriorSection
    :items="options.interior"
    :images="interiorImages"
    @select="emit('select', $event, 'interior')"
  />
  <ChassisSectionV2
    :items="options.chassis"
    :images="{ side: imageChassisSection }"
    :offer-template="offerTemplate"
    :spec-type="specType"
    :is-frame-agreement="isFrameAgreement"
    :chassis-description-key="chassisHighlights"
    class="chassis-section"
    @select="emit('select', $event, 'chassis')"
  />
  <FifthWheelSection
    :items="options.fifthWheel"
    :images="interiorImages"
    @select="emit('select', $event, 'fifthWheel')"
  />
  <SafetySectionGroup
    :items="options.safety"
    @select="emit('select', $event, 'safety')"
  />
  <FuelEfficiencySection
    :items="options.fuelEfficiency"
    :images="{ view: images?.view }"
    @select="emit('select', $event, 'fuelEfficiency')"
  />
  <ConnectedServicesSection
    :skip-ecolution-validation="true"
    :items="options.connectedServices"
    @select="emit('select', $event, 'connectedServices')"
  />
  <RepairMaintenanceSection
    :items="options.repairMaintenance"
    @select="emit('select', $event, 'repairMaintenance')"
  />
</template>

<style scoped>
  .chassis-section :deep(.configurator-section__image-container) {
    max-width: 70em;
  }

  .chassis-section :deep(.configurator-section__image-container > .configurator-section__image) {
    flex: 0.6;
  }

  .chassis-section :deep(.configurator-section__image-container > .configurator-section__image-text) {
    flex: 0.4;
  }

  .chassis-section :deep(.configurator-section__image > img) {
    max-width: 100%;
  }

  @media only screen and (max-width: 1294px) {
    .chasis-section :deep(.configurator-section__image > img) {
        max-width: 100%;
      }
  }
  @media only screen and (max-width: 1000px) {
    .chassis-section :deep(.configurator-section__image > img) {
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 600px) {
    .chassis-section :deep(.configurator-section__image > img) {
      max-width: 80%;
    }
    .chassis-section :deep(.configurator-section__image-container > .configurator-section__image-text) {
      max-width: 80%;
    }
  }
</style>
