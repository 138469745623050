<script setup>
import { ref, computed } from 'vue';
import Slider from '@vueform/slider';
import InformationModal from './InformationModal';
import InformationIcon from './InformationIcon';

import '../styles/configurator-selection.css';

const emit = defineEmits(['select', 'update:modelValue']);
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Object,
    default: () => {
      return null;
    },
  },
  showTooltip: {
    type: Boolean,
    default: true,
  },
  showTooltipValue: {
    type: Boolean,
    default: false,
  },
  tooltipValue: {
    type: String,
    default: '',
  },
  showSliderExtremeValues: {
    type: Boolean,
    default: false,
  },
  sliderExtremeValuesFlexAuto: {
    type: Boolean,
    default: false,
  },
  showInSidebar: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: true,
  },
  showInLineWidth: {
    type: String,
    default: '100%',
  },
  showInformation: {
    type: Boolean,
    default: false,
  },
});

const sliderValue = ref(props.modelValue.id.at(-1));

const sliderMax = computed(() => {
  return props.options.length;
});

const valueProp = computed(() => {
  return props.showInSidebar ? 'description' : 'value';
});

const minValue = computed(() => {
  return props.options[0][valueProp.value];
});

const maxValue = computed(() => {
  return props.options[props.options.length-1][valueProp.value];
});

const minValueOption = computed(() => {
  return props.options[0] || null;
});

const maxValueOption = computed(() => {
  return props.options[props.options.length-1] || null;
});

// TODO: get computed to compute in time (currently returns old value when slider changes)
// const selectedOption = computed(() => {
//   return props.options.find(x => x.id === `${props.type}-${sliderValue.value}`);
// });

const formatSlideTooltip = (sliderVal) => {
  return props.options.find(x => x.id === `${props.type}-${sliderVal}`)[valueProp.value];
};

const handleSliderChange = (sliderVal) => {
  if(props.modelValue.id === `${props.type}-${sliderVal}`) return;
  const selectObject = {
    type: props.type,
    option: props.options.find(x => x.id === `${props.type}-${sliderVal}`),
  };
  emit('select', selectObject);
  emit('update:modelValue', selectObject.option);
};

</script>

<template>
  <div
    class="configurator-selection"
    :class="{'sdds-u-relative': props.showTooltipValue && !showInSidebar}"
  >
    <h3
      v-if="props.title && !showInSidebar"
      class="configurator-selection__title"
    >
      {{ $i18n(props.title) }}
    </h3>
    <div
      v-if="props.showTooltipValue && !showInSidebar"
      class="sdds-u-absolute sdds-u-pt3 sdds-u-mt3 sdds-detail-07"
    >
      {{ formatSlideTooltip(sliderValue) }} ({{ props.tooltipValue }})
    </div>
    <div
      v-if="showInSidebar"
      class="show-inline"
      :style="{ width: props.showInLineWidth }"
    >
      <div><strong>{{ $i18n(props.title) }}</strong></div>
      <div class="sdds-u-textalign-end">
        {{ $i18n(formatSlideTooltip(sliderValue)) }}
        <div
          v-if="props.showTooltipValue"
          class="sdds-detail-07"
        >
          {{ props.tooltipValue }}
        </div>
      </div>
    </div>
    <div
      class="configurator-selection__options configurator-selection__options--slider"
      :class="{'configurator-selection__options--slider-inline': showInSidebar}"
    >
      <span
        v-if="showSliderExtremeValues"
        :class="{ 'slider-extreme-values-flex-auto': sliderExtremeValuesFlexAuto }"
        class="sdds-u-relative"
      >
        {{ $i18n(minValue) }}
        <div
          v-if="props.showInformation && (minValueOption.information || minValueOption.informationImage)"
          id="minValueModalId"
          :data-analytics="`{'eventComponent':'configuration','eventType':'modal','eventLabel':'More information - ${$i18n(minValueOption[valueProp], [], 'en-GB')} - ${minValueOption.id}'}`"
          class="value-info-icon-container"
        >
          <InformationIcon />
        </div>
      </span>
      <Slider
        v-model="sliderValue"
        :min="1"
        :max="sliderMax"
        :tooltips="props.showTooltip"
        :format="formatSlideTooltip"
        :lazy="false"
        @update="handleSliderChange"
      />
      <span
        v-if="showSliderExtremeValues"
        :class="{ 'slider-extreme-values-flex-auto': sliderExtremeValuesFlexAuto }"
        class="sdds-u-relative"
      >
        {{ $i18n(maxValue) }}
        <div
          v-if="props.showInformation && (maxValueOption.information || maxValueOption.informationImage)"
          id="maxValueModalId"
          :data-analytics="`{'eventComponent':'configuration','eventType':'modal','eventLabel':'More information - ${$i18n(maxValueOption[valueProp], [], 'en-GB')} - ${maxValueOption.id}'}`"
          class="value-info-icon-container"
        >
          <InformationIcon />
        </div>
      </span>
    </div>
  </div>
  <InformationModal
    v-if="props.showInformation && (minValueOption.information || minValueOption.informationImage)"
    id="minValueModalId"
    :title="`${$i18n(props.title)} ${$i18n(minValueOption[valueProp])}`"
    :information-text="$i18n(minValueOption.information)"
    :information-image="minValueOption.informationImage"
  />
  <InformationModal
    v-if="props.showInformation && (maxValueOption.information || maxValueOption.informationImage)"
    id="maxValueModalId"
    :title="`${$i18n(props.title)} ${$i18n(maxValueOption[valueProp])}`"
    :information-text="$i18n(maxValueOption.information)"
    :information-image="maxValueOption.informationImage"
  />
</template>

<style scoped>
  @import '@vueform/slider/themes/default.css';
</style>

<style scoped>
  .configurator-selection__options--slider {
    gap: 2em;
    place-content: center;
    place-items: center;
  }
    .configurator-selection__options--slider > span {
      flex: 0 0 3em;
      text-align: center;
    }
    .configurator-selection__options--slider > span.slider-extreme-values-flex-auto {
      flex: auto;
    }
    .configurator-selection__options--slider > div { flex: 0 0 160px; }
    .configurator-selection__options--slider-inline > div { flex: 0 0 200px; }
  .show-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
    margin-top: var(--sdds-spacing-element-24);
  }
  .value-info-icon-container {
    cursor: pointer;
    position: absolute;
    left: 7px;
  }
</style>

<style>
  :root {
    --slider-bg: var(--sdds-grey);
    --slider-connect-bg: none;

    --slider-tooltip-font-size: 1.0em;
    --slider-tooltip-line-height: 2.5em;
    --slider-tooltip-bg: var(--sdds-grey-800);
    --slider-tooltip-font-weight: 500;

    --slider-handle-bg: var(--sdds-blue);
    --slider-handle-shadow: none;
  }
</style>
