<script setup>
import { inject, computed } from 'vue';
import SddsIconCustom from './SddsIconCustom.vue';
import { useRouter } from 'vue-router';
import { useMainStore } from '../store/index.js';

const adobeEvent = inject('adobeEvent');

const store = useMainStore();
const router = useRouter();

const props = defineProps(
  {
    title: {
      type: String,
      default: '',
    },
    offerId: {
      type: String,
      default: '',
    },
    keypoints: {
      type: Array,
      default () {
        return [];
      },
    },
    imageUrl: {
      type: String,
      default: '',
    },
    offerStatus: {
      type: Object,
      default() {
        return {};
      },
    },
    offerData: {
      type: Object,
      default() {
        return {};
      },
    },
    cardHeaderChips: {
      type: Object,
      default() {
        return {};
      },
    },
    cardChips: {
      type: Object,
      default() {
        return {};
      },
    },
    additionalInfo: {
      type: String,
      default: '',
    },
  },
);

const showCardHeader = computed(() => {
  return (Object.keys(props.cardChips).length || (props.cardHeaderChips.specialOffer || props.cardHeaderChips.ecolution)) ||
    (props.offerStatus.expired || props.offerStatus.finalized || props.offerStatus.isOfferNotValid);
});

const sendTracking = () => {
  adobeEvent({
    event: 'setupOffer',
    offerInfo: {
      offerId: props.offerId,
    },
  });
};

const goToConfigure = () => {
  sendTracking();
  router.push({ name: 'ConfiguratorView', params: { market: store.market }, query: { id: props.offerId } });
};
</script>
<template>
  <div class="card-container">
    <template v-if="showCardHeader">
      <div class="card-header">
        <template v-if="offerStatus.expired">
          <div class="sdds-chip sdds-chip__icon-right card-header-chip-with-icon">
            <SddsIconCustom name="clock" />
            <span class="sdds-chip-text">{{ $i18n('availableOffersOfferExpired') }}</span>
          </div>
        </template>
        <template v-if="offerStatus.isOfferNotValid">
          <div class="sdds-chip sdds-chip__icon-right card-header-chip-with-icon">
            <SddsIconCustom />
            <span class="sdds-chip-text">{{ $i18n('availableOffersSoldOut') }}</span>
          </div>
        </template>
        <template v-if="!offerStatus.expired && !offerStatus.isOfferNotValid && !offerStatus.finalized">
          <template v-if="Object.keys(cardChips).length">
            <div
              v-for="(cardChipColor, cardChipTranslationKey) in cardChips"
              :key="cardChipTranslationKey"
              class="sdds-chip card-header-chip"
              :style="`background-color: ${cardChipColor}`"
            >
              <span class="sdds-chip-text">{{ $i18n(cardChipTranslationKey) }}</span>
            </div>
          </template>
          <template v-else>
            <template v-if="cardHeaderChips.specialOffer">
              <div class="sdds-chip card-header-chip chip-special-offer">
                <span class="sdds-chip-text">{{ $i18n('availableOffersSpecialOffer') }}</span>
              </div>
            </template>
            <template v-if="cardHeaderChips.ecolution">
              <div class="sdds-chip card-header-chip chip-ecolution">
                <span class="sdds-chip-text">{{ $i18n('availableOffersEcolution') }}</span>
              </div>
            </template>
          </template>
        </template>
      </div>
    </template>
    <div class="card-top">
      <img
        :src="imageUrl"
        alt="Card Image"
        class="card-img"
      >
    </div>
    <div
      class="card"
      :class="{ 'card-disabled': offerStatus.expired || offerStatus.finalized || offerStatus.isOfferNotValid, 'sdds-u-pt0': showCardHeader }"
    >
      <div class="card-body">
        <p class="sdds-headline-03">
          {{ $i18n(title) }}
        </p>
        <ul class="card-keypoints">
          <li
            v-for="keypoint in keypoints"
            :key="keypoint.icon"
          >
            <div class="sdds-row">
              <div class="sdds-col keypoint-icon">
                <SddsIconCustom :name="keypoint.icon" />
              </div>
              <div class="sdds-col keypoint-info">
                {{ $i18n(keypoint.translationKey) }}
              </div>
            </div>
          </li>
        </ul>
        <template v-if="offerStatus.expired || offerStatus.finalized || offerStatus.isOfferNotValid">
          <button
            class="sdds-btn sdds-btn-primary sdds-btn-fullbleed"
            disabled
            data-dd-action-name="build and buy card"
          >
            {{ $i18n('buildAndBuyButton') }} <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.958 26.314a1 1 0 1 0 1.415 1.414l9.89-9.89a2.6 2.6 0 0 0 0-3.676l-9.89-9.89a1 1 0 1 0-1.415 1.414L27.273 15H2.976a1 1 0 1 0 0 2h24.296l-9.314 9.314Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <div
            v-if="offerStatus.expired"
            class="sdds-u-pt1"
          >
            {{ $i18n('offerExpired') }} 
            <template v-if="offerData.offerValidDate && offerData.offerValidDate.length && offerData.offerValidDate[0]">
              ({{ offerData.offerValidDate[0] }})
            </template>
          </div>
          <div
            v-if="offerStatus.finalized"
            class="sdds-u-pt1"
          >
            {{ $i18n('confirmationViewHeading') }}
          </div>
          <div
            v-if="offerStatus.isOfferNotValid"
            class="sdds-u-pt1"
          >
            {{ $i18n('offerNotValid') }}
          </div>
        </template>
        <template v-else>
          <a
            class="sdds-btn sdds-btn-primary sdds-btn-fullbleed"
            data-dd-action-name="build and buy"
            @click="goToConfigure()"
          >
            {{ $i18n('buildAndBuyButton') }} <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.958 26.314a1 1 0 1 0 1.415 1.414l9.89-9.89a2.6 2.6 0 0 0 0-3.676l-9.89-9.89a1 1 0 1 0-1.415 1.414L27.273 15H2.976a1 1 0 1 0 0 2h24.296l-9.314 9.314Z"
                fill="currentColor"
              />
            </svg>
          </a>
          <div
            v-if="offerData?.offerValidDate && offerData.offerValidDate.length && offerData.offerValidDate[0]"
            class="sdds-u-pt1"
          >
            {{ $i18n('heroOfferValidPeriod', offerData.offerValidDate) }}
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="props.additionalInfo"
      class="card-accordion"
    >
      <sdds-accordion>
        <sdds-accordion-item :header="$i18n('moreInformationText')">
          {{ $i18n(props.additionalInfo) }}
        </sdds-accordion-item>
      </sdds-accordion>
    </div>
  </div>
</template>

<style scoped>
.sdds-btn-fullbleed {
  width: fit-content;
}
.card-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: var(--sdds-spacing-layout-48);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  background-image: linear-gradient(#A9A9A9, var(--sdds-grey-50), var(--sdds-grey-50));
  position: relative;
}
.card-header {
  position: absolute;
  width: 100%;
  padding: var(--sdds-spacing-element-16);
  padding-bottom: var(--sdds-spacing-element-8);
}
.card-header .sdds-chip {
  cursor: default;
}
.card-header .card-header-chip-with-icon object {
  height: 16px;
  width: 16px;
  margin-right: 0;
  position: absolute;
  right: 8px;
}
.card-header .card-header-chip.chip-special-offer {
  background-color: #DA2B40;
}
.card-header .card-header-chip.chip-ecolution {
  background-color: #D95610;
}
.card-header .card-header-chip span.sdds-chip-text {
  color: #fff;
}

.card-top {
  padding-top: var(--sdds-spacing-element-40);
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#A9A9A9, var(--sdds-grey-50), var(--sdds-grey-50));
}


.card {
  display: flex;
  flex-direction: column;
  background-color: var(--sdds-grey-50);
}

.card-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.card-img {
  height: auto;
  object-fit: cover;
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: var(--sdds-spacing-element-20) var(--sdds-spacing-element-20);
  background-color: var(--sdds-grey-50);
}

.card-accordion{
  margin-top: auto;
  background-color: var(--sdds-grey-50);
}

.card-body > button, .card-body > a {
  display: flex;
  place-content: center;
  align-items: center;
  margin-top: auto;
  color: white;
}
.card-body > button > svg, .card-body > a > svg {
  line-height: 0;
  margin-left: var(--sdds-spacing-element-12);
  height: 6rem;
  width: 6rem;
}

.card-keypoints {
  list-style: none;
  margin: auto 0;
  padding: 0;
}

.card-keypoints li {
  margin-bottom: var(--sdds-spacing-layout-8);
}

.sdds-headline-03 {
  margin: unset;
  margin-bottom: var(--sdds-spacing-element-12);
}

.card-keypoints li .keypoint-icon {
  max-width: fit-content;
  padding: 0;
}

.card-keypoints li .keypoint-info {
  line-height: calc(((24 / 4))* 1rem);
  padding: 0 0 0 4px;
}

.card-additional-info{
  margin-top: var(--sdds-spacing-element-4);
}

@media only screen and (max-width: 1024px) {
  .active-offers__card-list > * {
    width: 100%;
    margin: unset;
  }
}

@media only screen and (max-width: 768px) {
  .card {
    flex-direction: column;
  }
  .card-img {
    width: 100%;
  }
  .card-body {
    margin-top: var(--sdds-spacing-element-16);
    width: 100%;
  }
}
</style>
